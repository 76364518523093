
import { VideoWallItemType } from "@evercam/shared/types/videoWall"
import VideoWallGallery from "@/components/videoWalls/VideoWallGallery"
import { useVideoWallStore } from "@/stores/videoWall"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { AnalyticsEvent, AnalyticsEventPageId } from "@evercam/shared/types"

export default {
  name: "ProjectVideoWall",
  meta: {
    pageId: AnalyticsEventPageId.videoWall,
  },
  components: { VideoWallGallery },
  async fetch() {
    const videoWallStore = useVideoWallStore()
    await videoWallStore.fetchVideoWallConfiguration()
  },
  head() {
    return {
      title: "Evercam | Video Wall",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useVideoWallStore, useProjectStore),
    items() {
      return this.projectStore.selectedProjectCameras.map((c) => ({
        value: c,
        type: VideoWallItemType.liveView,
      }))
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
  },
}
